.container {
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 86px);
    
    .content {
        align-items: center;
        justify-content: center;
        flex: 1;
        gap: var(--gap);

        .top {
            align-items: center;
            width: 100%;
    
            .logo {
                width: 50%;
            }
            h1 {
                margin-top: -10px;
                font-size: 2rem;
            }
        }
        .search {
            width: 80%;
            background: #fff;
            box-shadow: inset 0 0 2px rgba(0,0,0,0.05);
            border-radius: 5px;
            position: relative;
            align-items: center;
            justify-content: center;
            font-size: 1.2rem;
            color: #757575;
    
            input {
                width: 100%;
                padding: 16px 32px;
                border: none;
                background-color: transparent;
                outline: none;
                color: inherit;
                font-size: inherit;
    
                &::-ms-clear {
                    display: none;
                }
            }
    
            svg {
                position: absolute;
                left: 10px;
                height: 40%;
                color: inherit;
            }
        }
    }
}
.article {
    gap: 20px;
    article {
        gap: 20px;
    }
}
.moreLink {
    display: flex;
    align-items: center;
    font-size: 2rem;
    color: var(--color-main);
    font-weight: 700;
    margin: 40px 0;
    white-space: nowrap;
    gap: 10px;
    animation: bounce 1s infinite;

    svg {
        width: 20px;
    }
}
.more {
    background-color: #f8f8f8;
    padding: var(--gap) 0;
    align-items: center;
}
@media screen and (min-width: 769px) {
    .more {
        width: 100%;
        box-shadow: var(--shadow);
    
        h2 {
            text-align: center;
            font-size: 4rem;
        }
    }
    .article {
        flex-direction: row;
        padding: 0 20px;

        .banner {
            width: 300px;
            height: 300px;
        }
    }
}
@media screen and (max-width: 768px) {
    .container {
        min-height: unset;
        padding: 20px 0 0 0;
        
        .content {
            justify-content: flex-start;

            .top {
                .logo {
                    width: 60%;
                }
                h1 {
                    font-size: 1.2rem;
                }
            }
        }
    }
    .moreLink {
        font-size: 1.2rem;
        margin: 0;
    }
    .article {
        article {
            padding: 0 20px 20px 20px;
        }
        .banner {
            width: 100%;
        }
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}